import { useNavigationQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {}

export const FreshChat = ({}: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data: navigationData } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  return navigationData?.pageCollection?.items?.[0]?.chatEnabled ? (
    <Script
      id="freshchat"
      strategy="lazyOnload"
      type="text/javascript"
      data-usercentrics="Freshchat"
      dangerouslySetInnerHTML={{
        __html: `function initFreshChat() {
          window.fcWidget.init({
            token: "dd9363ed-9ad6-43fd-8bca-5ddacc3c70ee",
            host: "https://wchat.freshchat.com",
            locale: "${router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE}"
          });
        }
        (function(d, id) {
          var fcJS;
          if (d.getElementById(id)) {
              initFreshChat();
              return;
          }
          fcJS = d.createElement('script');
          fcJS.id = id;
          fcJS.async = true;
          fcJS.src = 'https://wchat.freshchat.com/js/widget.js';
          fcJS.onload = initFreshChat;
          d.head.appendChild(fcJS);
      }(document, 'freshchat-js-sdk'));`,
      }}
    />
  ) : null
}

export default FreshChat
